import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'error-page-1',
        path: `${AUTH_PREFIX_PATH}/error-page-1`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
    },
    {
        key: 'error-page-2',
        path: `${AUTH_PREFIX_PATH}/error-page-2`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-2')),
    },
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'profile-edit',
        path: `${APP_PREFIX_PATH}/profile/edit`, // Include ':userId' as a parameter
        component: React.lazy(() => import('views/app-views/components/users/edit-profile')), // Import the component for editing a user
    },
    {
        key: 'user-default',
        path: `${APP_PREFIX_PATH}/components/users/user-list`,
        component: React.lazy(() => import('views/app-views/components/users/user-list')),
    },
    {
        key: 'user-add',
        path: `${APP_PREFIX_PATH}/components/users/Register`,
        component: React.lazy(() => import('views/app-views/components/users/Register')),
    },
    {
        key: 'user-edit',
        path: `${APP_PREFIX_PATH}/components/users/user-list/EditUserForm/:userId`, // Include ':userId' as a parameter
        component: React.lazy(() => import('views/app-views/components/users/user-list/EditUserForm')), // Import the component for editing a user
    },
    {
        key: 'client-add',
        path: `${APP_PREFIX_PATH}/components/users/create-client-profile`,
        component: React.lazy(() => import('views/app-views/components/users/create-client-profile')),
    },
    {
        key: 'client-edit',
        path: `${APP_PREFIX_PATH}/components/users/client-list/EditClientProfileForm/:userId`, // Include ':userId' as a parameter
        component: React.lazy(() => import('views/app-views/components/users/client-list/EditClientProfileForm')), // Import the component for editing a user
    },
    {
        key: 'model-add',
        path: `${APP_PREFIX_PATH}/components/users/create-model-profile`,
        component: React.lazy(() => import('views/app-views/components/users/create-model-profile')),
    },
    {
        key: 'model-edit',
        path: `${APP_PREFIX_PATH}/components/users/model-list/EditModelProfileForm/:userId`, // Include ':userId' as a parameter
        component: React.lazy(() => import('views/app-views/components/users/model-list/EditModelProfileForm')), // Import the component for editing a user
    },
    {
        key: 'client-profile',
        path: `${APP_PREFIX_PATH}/components/users/client-list`,
        component: React.lazy(() => import('views/app-views/components/users/client-list')),
    },
    {
        key: 'model-profile',
        path: `${APP_PREFIX_PATH}/components/users/model-list`,
        component: React.lazy(() => import('views/app-views/components/users/model-list')),
    },
    {
        key: 'setting-coinspricing',
        path: `${APP_PREFIX_PATH}/setting/coinspricing`,
        component: React.lazy(() => import('views/app-views/setting/CoinPrices')),
    },
    {
        key: 'setting.news',
        path: `${APP_PREFIX_PATH}/setting/news`,
        component: React.lazy(() => import('views/app-views/setting/news')),
    },
    {
        key: 'setting.galery',
        path: `${APP_PREFIX_PATH}/setting/galery`,
        component: React.lazy(() => import('views/app-views/galery/galery')),
    },
    {
        key: 'setting.news.create',
        path: `${APP_PREFIX_PATH}/setting/news/create`,
        component: React.lazy(() => import('views/app-views/setting/news/create')),
    },
    {
        key: 'setting.news.create',
        path: `${APP_PREFIX_PATH}/setting/news/view/:slug`,
        component: React.lazy(() => import('views/app-views/setting/news/view')),
    },
    {
        key: 'setting.news.create',
        path: `${APP_PREFIX_PATH}/setting/news/edit/:slug`,
        component: React.lazy(() => import('views/app-views/setting/news/edit')),
    },
    {
        key: 'setting.package',
        path: `${APP_PREFIX_PATH}/setting/packages`,
        component: React.lazy(() => import('views/app-views/coins/packages')),
    },
    {
        key: 'invitation',
        path: `${APP_PREFIX_PATH}/invitation`,
        component: React.lazy(() => import('views/app-views/invitation')),
    },
    {
        key: 'bookings',
        path: `${APP_PREFIX_PATH}/bookings`,
        component: React.lazy(() => import('views/app-views/bookings')),
    },
    {
        key: 'videorequest',
        path: `${APP_PREFIX_PATH}/bookings/videorequest`,
        component: React.lazy(() => import('views/app-views/bookings/videorequest')),
    },
    {
        key: 'reports',
        path: `${APP_PREFIX_PATH}/reports`,
        component: React.lazy(() => import('views/app-views/reports/ReportsPage')),
    },
    {
        key: 'coins',
        path: `${APP_PREFIX_PATH}/coins`,
        component: React.lazy(() => import('views/app-views/coins/CoinTransactions')),
    },
    {
        key: 'creditnotes',
        path: `${APP_PREFIX_PATH}/creditnotes`,
        component: React.lazy(() => import('views/app-views/coins/CreditNotesPage')),
    },
    {
        key: 'videocall',
        path: `${APP_PREFIX_PATH}/videocall`,
        component: React.lazy(() => import('views/app-views/videocall/videocall')),
    },
    {
        key: 'notification',
        path: `${APP_PREFIX_PATH}/notifications`,
        component: React.lazy(() => import('views/app-views/notification')),
    },
    {
        key: 'contacts',
        path: `${APP_PREFIX_PATH}/contacts`,
        component: React.lazy(() => import('views/app-views/contacts')),
    },
]
